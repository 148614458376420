import * as React from "react"
import Button from "../components/forms/Button"
import Section from "../components/Section"
import '../layout/cssreset.scss';
import '../layout/template.scss';

// markup
const NotFoundPage = () => {
  return (
    <Section backgroundColor='transparent' className="dc-404">
      <div className="dc-section-head">
        <h1>404</h1>
        <h2>Not found</h2>
        <a href="/">
          <Button outline variant="black" label="Go to home" />
        </a>
      </div>
    </Section>
  )
}

export default NotFoundPage
