import React from 'react';

const Section = ({ children, className = '', backgroundImage, backgroundColor }) => {
  const bgImage = backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : {};
  const bgColor = backgroundColor ? { backgroundColor } : {};
  const bgStyle = { ...bgImage, ...bgColor };

  return (
    <div className={`dc-section ${className}`} style={bgStyle}>
      {/* <ScrollInto> */}
        {children}
      {/* </ScrollInto> */}
    </div>
  )
};

export default Section;